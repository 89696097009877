// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import publicTastingInformationStyle from "assets/jss/material-kit-pro-react/views/publicTastingPageSections/publicTastingInformationStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(publicTastingInformationStyle)

export default function PublicTastingInformation() {
  const { publicTastingInformationBackground } = useStaticQuery(graphql`
    query getPublicTastingInformationImages {
      publicTastingInformationBackground: file(
        relativePath: { eq: "public-tasting/public-tasting-2.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={
          publicTastingInformationBackground.childImageSharp.gatsbyImageData
        }
        alt="Register Now Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={10} md={8}>
              <h2 className={classes.title}>
                What is the public chili tasting?
              </h2>
              <p className={classes.description}>
                <b>
                  The public chili tasting is your chance to taste all the great
                  chili, lovingly cooked on-site by cook-off participants. Each
                  contestant is required to make two (2) or more gallons of
                  chili to share with the crowd. Attendees can taste as many
                  samples as they like, while supplies last.
                </b>
              </p>
              <p className={classes.description}>
                After the tasting, People’s Choice ballots can be returned to
                the collection box near the Main Stage. Votes are tabulated and
                winners announced from the Main Stage during our awards ceremony
                beginning at 5:15pm
              </p>
              <Link to="/past-winners">
                <Button color="primary">Past Chilimania Winners</Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
